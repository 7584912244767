import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';

import { DmTableController } from '@dimanoid/ngx-dm-table';
import { compareDesc } from 'date-fns';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';

import { removeW } from '@base/base-animations';
import { BaseComponent } from '@base/base.component';
import { BaseFilter, FilterFieldType, FilterTemplate, stopEvent, Tag } from '@models';
import { InputBoolean } from '@models/utils/coercions';
import { StoreService } from '@services/store.service';
import { FilterController } from '@shared/_controls/filter-form';

@Component({
    selector: 'vz-ctrl-search, [vz-ctrl-search]',
    templateUrl: './ctrl-search.component.html',
    styleUrls: ['./ctrl-search.component.less'],
    host: { 'class': 'layout flex center' },
    animations: [removeW],
    standalone: false
})
@Tag('CtrlSearchComponent')
export class CtrlSearchComponent extends BaseComponent implements OnInit, OnChanges {
    _hostFlex = true;
    @Input() controller?: DmTableController<any>;
    @Input() filterController?: FilterController;
    @Input() placeholder?: string;
    @Input() formVisible: boolean = false;
    @Input() @InputBoolean() showFilter: boolean | string = true;
    @Output() formVisibleChange: EventEmitter<boolean> = new EventEmitter();

    filter?: BaseFilter<any>;
    filters: { id: string, name: string, value: any }[] = [];
    filterDebouncer: Subject<string | null> = new Subject();
    fCount: string = '';
    fHasHidden: boolean = false;
    fText: string = '';

    filterTemplates?: FilterTemplate[];
    newFilterTemplate?: FilterTemplate;
    showAllFilterTemplates: boolean = false;
    helpDrawer?: NzDrawerRef<any, any>;
    formController: FilterController = new FilterController();
    fcEmpty: boolean = true;

    FFT = FilterFieldType;
    compareById = (a: any, b: any) => a?.id == b?.id;

    constructor(
        protected _store: StoreService,
        private _drawer: NzDrawerService,
    ) {
        super(_store);
    }

    ngOnInit(): void {
        // this._W('ngOnInit', this.filter, this.pref);
        this.__initPrefs({
            showFP: 'showFilterPanel',
        });
        this._S.debouncer = this.filterDebouncer.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(f => this.updateFilterText(f || ''));
        this._S.formController = this.formController.text.subscribe(text => this.fcEmpty = !text);
        this._S.controller = this.controller!.state.subscribe(st => {
            this.fCount = st.itemsVisible + (this.mobile() ? '/' : ' из ') + st.itemsTotal;
            this.fHasHidden = st.itemsTotal != st.itemsVisible;
        });
        if (this.filterController) {
            this.filter = this.filterController.getFilter();
            this._S.fcontroller = this.filterController.text.pipe(debounceTime(300)).subscribe(text => {
                this.controller?.filter.next(text);
                this.updateActiveFiltersList();
            });
            this._S.controller = this.filterController.defText.subscribe(text => this.fText = text);
        }
        this.updateFilterTemplates();
    }

    ngOnChanges(changes: SimpleChanges): void {
        // this._W('ngOnChanges', changes);
        if (changes.formVisible?.currentValue) {
            this.updateFilterTemplates();
        }
    }

    updateActiveFiltersList(): void {
        this.filters = [];
        if (this.filter && this.filterController) {
            this.filters = this.filterController.getChangedFields();
        }
    }

    updateFilterTemplates(): void {
        // this._L('updateFilterTemplates', this.filter?.id);
        this.filterTemplates = this._store.getState('prefs').filterTemplates.filter(ft => ft.filterId == this.filter?.id).sort((a, b) => compareDesc(a.lastUsed || a.created, b.lastUsed || b.created));
        const filter = this.filterController?.getFilter();
        if (filter) {
            this.formController.init(filter, { projectId: this.filterController?.projectId.getValue(), text: this.filterController?.text.getValue() });
        }
    }

    openHelpDrawer(ttl: TemplateRef<any> | string, content: TemplateRef<any>): void {
        this.formVisibleChange.emit(true);
        this.helpDrawer = this._drawer.create({
            nzTitle: ttl,
            nzContent: content,
            nzWidth: '100%',
            nzPlacement: 'left',
            nzBodyStyle: this.mobile() ?  { padding: '8px' } : undefined
        });
    }

    checkFilter(open: boolean): void {
        this.formVisibleChange.emit(open);
        if (open) {
            this.updateFilterTemplates();
        }
        else {
            // this.values = undefined;
            this.newFilterTemplate = undefined;
        }
    }

    clearForm(): void {
        this.filterController?.setText('');
        this.close();
    }

    search(): void {
        this.filterController?.setValues(this.formController.values.getValue() || {});
        this.close();
    }

    updateFilterText(text: string): void {
        // this._L('updateFilterText', `"${text}"`);
        if (text != this.fText) {
            this.filterController?.setDefText(text);
        }
        // this.search();
    }

    saveAsTemplate(): void {
        const filter = this.formController?.text.getValue();
        this._L('saveAsTemplate', 'filter:', filter, 'fcEmpty:', this.fcEmpty);
        this.newFilterTemplate = filter ? new FilterTemplate({ filterId: this.filter?.id, filter }) : undefined;
    }

    saveTemplate(): void {
        // this._L('saveTemplate', this.newFilterTemplate);
        this.filterTemplates?.unshift(this.newFilterTemplate!);
        const fts = this._store.getState('prefs').filterTemplates;
        fts.push(this.newFilterTemplate!);
        this._store.patchState('prefs', { filterTemplates: fts });
        this.newFilterTemplate = undefined;
    }

    applyTemplate(ft: FilterTemplate): void {
        this._L('applyTemplate', ft);
        if (ft?.filter) {
            this.filterTemplates = this.filterTemplates!.filter(f => f.id != ft.id );
            const nft = new FilterTemplate(ft).parse({ usedCount: ft.usedCount + 1, lastUsed: new Date() });
            this.filterTemplates.push(nft);
            this._store.patchState('prefs', { filterTemplates: this.filterTemplates });
            this.filterController?.setText(ft.filter);
            this.formController.setText(ft.filter);
            if (this.mobile()) {
                this.pref.showFP = false;
            }
        }
    }

    removeFilterTemplate(ft: FilterTemplate, e: MouseEvent): void {
        stopEvent(e);
        // this._L('removeFilterTemplate', ft);
        this.filterTemplates = this.filterTemplates!.filter(f => f.id != ft.id );
        const fts = this._store.getState('prefs').filterTemplates.filter(f => f.id != ft.id );
        this._store.patchState('prefs', { filterTemplates: fts });
    }

    close(): void {
        this.formVisible = false;
        this.formVisibleChange.emit(false);
        if (this.mobile() && this.helpDrawer) {
            this.helpDrawer.close();
            this.helpDrawer = undefined;
        }
    }

    clearFilter(id?: string): void {
        if (id) {
            const values = this.filterController?.values.getValue();
            if (values) {
                delete values[id];
            }
            this.filterController?.setValues(values || {});
        }
        else {
            this.filterController?.setText('');
        }
    }

    L(...args: any): void {
        this._L('HTML', ...args);
    }

}
